:root {
  --color-table-background: rgba(239, 239, 244, 1);
  --color-primary: #cb2027;
  --bg-dark: rgba(50, 50, 63, 1);
}

.bg-gtb {
  background-color: var(--color-table-background);
}

.App {
  text-align: center;
}
body {
  padding: 0px;
  margin: 0px;
}

.login {
  background-color: #cb2027;
  height: 100vh;
  padding-top: 4rem;
  margin-top: 0px;
}

.nav-raised {
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.5);
}

.main-dashboard-container {
  margin-left: 260px;
  overflow: hidden;
}

.full-page {
  min-height: 90vh;
}
.side-detail {
  position: absolute;
  height: 92vh;
  left: 0;
  width: 250px;

  background-color: white;
}

/*
    <color name="colorPrimary">#cb2027</color>
    <color name="colorSecondary">#E53935</color>
    <color name="colorPrimaryDark">#303F9F</color>
    <color name="colorAccent">#FF4081</color>
    <color name="red">#e94949</color>
    <color name="clear">#6fafafaf</color>
    <color name="theme_dark">#141414</color>
    <color name="evedark">#757575</color>
    <color name="textbg">#c9c9c9</color>
    <color name="recycler_bgg">#ffebee</color>
*/

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.hiddenRow {
  padding: 0 !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.about-container {
  background-color: #141414;
  height: 100vh;
  padding-top: 4rem;
  margin-top: 0px;

  display: flex;
  justify-content: center;
}

.container-item {
  padding: 30px;
}

.homescreen {
  height: 70vh;
  width: 40vh;
  margin-right: 100px;
  border-width: 2px;
  border-color: #eeeeee;
  border-style: double;
  border-radius: 4px;
}

.playbadge {
  height: 80px;
  width: 250px;
  margin-top: 50px;
}

.service-about {
  height: 200px;
  width: 400px;
}

.footer {
  background-color: #141414;
}

.card-common {
  box-shadow: 1px 2px 5px #999;
  transition: all 0.4s;
}

.card-common:hover {
  box-shadow: 2px 3px 15px #999;
  transform: translateY(-1px);
}

/*Task list*/
.task-border {
  border-left: 3px solid #f66436;
}

/**************** hero part css end ****************/
/**************** service_part css start ****************/
/* line 2, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
.feature_part {
  position: relative;
  z-index: 1;
}

/* line 5, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
.feature_part .feature_icon_1 {
  position: absolute;
  left: 19%;
  top: 50%;
  z-index: -1;
}

/* line 12, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
.feature_part .feature_icon_2 {
  left: 35%;
  top: 23%;
  position: absolute;
  z-index: -1;
}

/* line 18, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
.feature_part .feature_icon_3 {
  position: absolute;
  left: 48%;
  z-index: -1;
  bottom: 18%;
}

/* line 24, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
.feature_part .feature_icon_4 {
  position: absolute;
  right: 35%;
  top: 7%;
  z-index: -1;
}

@media (max-width: 576px) {
  /* line 30, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .feature_part .feature_part_text {
    padding-left: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 30, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .feature_part .feature_part_text {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 30, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .feature_part .feature_part_text {
    padding-left: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 30, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .feature_part .feature_part_text {
    padding-left: 0;
  }
}

/* line 47, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
.feature_part .feature_part_text h2 {
  font-size: 45px;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 21px;
  position: relative;
  text-transform: capitalize;
}

@media (max-width: 576px) {
  /* line 47, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .feature_part .feature_part_text h2 {
    font-size: 25px;
    margin-bottom: 10px;
    line-height: 35px;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 47, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .feature_part .feature_part_text h2 {
    font-size: 25px;
    margin-bottom: 10px;
    line-height: 35px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 47, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .feature_part .feature_part_text h2 {
    font-size: 25px;
    margin-bottom: 10px;
    line-height: 35px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 47, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .feature_part .feature_part_text h2 {
    font-size: 28px;
    margin-bottom: 20px;
    line-height: 40px;
  }
}

/* line 81, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
.feature_part .feature_part_text p {
  margin-bottom: 46px;
}

/* line 84, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
.feature_part .feature_part_text .feature_part_text_iner {
  border-radius: 50px;
  border-top-right-radius: 0;
  border: 1px solid #eeeeee;
  text-align: center;
  padding: 34px 27px 31px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

@media (max-width: 991px) {
  /* line 84, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .feature_part .feature_part_text .feature_part_text_iner {
    padding: 20px 15px 15px;
    margin-bottom: 15px;
  }
}

/* line 98, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
.feature_part .feature_part_text .feature_part_text_iner:hover {
  border: 1px solid #0ab6ff;
}

/* line 101, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
.feature_part .feature_part_text .feature_part_text_iner h4 {
  font-size: 40px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

/* line 106, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
.feature_part .feature_part_text .feature_part_text_iner p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.667;
}

/* line 115, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
.feature_part .single_feature {
  margin: 0 11px;
}

@media (max-width: 991px) {
  /* line 115, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .feature_part .single_feature {
    margin: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 115, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .feature_part .single_feature {
    margin: 0;
  }
}

/* line 124, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
.feature_part .single_feature:nth-child(2) {
  margin-top: 60px;
}

@media (max-width: 991px) {
  /* line 124, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .feature_part .single_feature:nth-child(2) {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 124, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .feature_part .single_feature:nth-child(2) {
    margin-top: 30px;
  }
}

/* line 135, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
.feature_part .single_feature_part {
  padding: 40px 25px 31px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 3px 81px 0px rgba(216, 216, 216, 0.45);
  background-color: #fff;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

@media (max-width: 991px) {
  /* line 135, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .feature_part .single_feature_part {
    padding: 25px 15px;
    margin-top: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 135, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .feature_part .single_feature_part {
    margin-top: 25px;
  }
}

/* line 152, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
.feature_part .single_feature_part img {
  margin-bottom: 21px;
  height: 65px;
}

@media (max-width: 991px) {
  /* line 152, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .feature_part .single_feature_part img {
    margin-bottom: 21px;
  }
}

/* line 162, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
.feature_part .single_feature_part img i {
  color: #0c2e60;
  font-size: 24px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

/* line 168, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
.feature_part .single_feature_part h4 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 22px;
}

@media (max-width: 576px) {
  /* line 168, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .feature_part .single_feature_part h4 {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 168, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .feature_part .single_feature_part h4 {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 168, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .feature_part .single_feature_part h4 {
    margin-bottom: 15px;
  }
}

/* line 189, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
.feature_part .single_feature_part p {
  color: #7f7f7f;
  line-height: 1.8;
  font-size: 15px;
}

/* line 196, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
.single_feature_padding {
  padding-top: 140px;
}

@media (max-width: 576px) {
  /* line 196, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .single_feature_padding {
    padding-top: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 196, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .single_feature_padding {
    padding-top: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 196, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .single_feature_padding {
    padding-top: 70px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 196, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_feature_part.scss */
  .single_feature_padding {
    padding-top: 70px;
  }
}

/* line 48, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_common.scss */
.padding_top {
  padding-top: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 48, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_common.scss */
  .padding_top {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 48, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_common.scss */
  .padding_top {
    padding-top: 50px;
  }
}

@media (max-width: 576px) {
  /* line 48, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_common.scss */
  .padding_top {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 48, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_common.scss */
  .padding_top {
    padding-top: 50px;
  }
}

/**********************footer part css*******************/
/* line 2, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px 25px;
}

@media (max-width: 576px) {
  /* line 2, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
  .footer_part {
    padding: 70px 0px 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 2, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
  .footer_part {
    padding: 70px 0px 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 2, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
  .footer_part {
    padding: 70px 0px 25px;
  }
}

/* line 20, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part img {
  margin-bottom: 35px;
}

@media (max-width: 576px) {
  /* line 24, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
  .footer_part .single_footer_part {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 24, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
  .footer_part .single_footer_part {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 24, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
  .footer_part .single_footer_part {
    margin-bottom: 20px;
  }
}

/* line 39, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part .single_footer_part h4 {
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: 500;
}

@media (max-width: 576px) {
  /* line 39, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
  .footer_part .single_footer_part h4 {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 39, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
  .footer_part .single_footer_part h4 {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 39, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
  .footer_part .single_footer_part h4 {
    margin-bottom: 15px;
  }
}

/* line 59, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part .single_footer_part p {
  margin-bottom: 10px;
}

/* line 65, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part .single_footer_part .list-unstyled li a {
  color: #7f7f7f;
  margin-bottom: 10px;
  display: inline-block;
}

/* line 70, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part .single_footer_part .list-unstyled li a:hover {
  color: #0ab6ff !important;
}

/* line 77, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part .single_footer_part .social_icon {
  float: left;
  margin-top: 38px;
}

@media (max-width: 991px) {
  /* line 77, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
  .footer_part .single_footer_part .social_icon {
    margin-top: 15px;
  }
}

/* line 88, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part .single_footer_part .social_icon li a {
  margin-left: 0;
  margin-right: 25px;
}

/* line 97, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part .single_footer_part .mail_part {
  position: relative;
}

/* line 100, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part .single_footer_part .mail_part input {
  padding: 9px 22px;
  font-size: 13px;
  border-radius: 50px;
  border: 2px solid #fff;
  margin-top: 20px;
  width: 100%;
  background-color: #f9f9fe;
  border: 1px solid #ebebeb;
}

/* line 111, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part .single_footer_part .mail_part .email_icon {
  position: absolute;
  right: 0px;
  top: 20px;
  width: 60px;
  height: 39px;
  background-color: #0ab6ff;
  text-align: center;
  color: #fff;
  border-radius: 50px;
  line-height: 10px;
  border: 2px solid #0ab6ff;
}

/* line 127, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part hr {
  background-color: #cad7dc;
  margin-top: 50px;
  margin-bottom: 25px;
}

/* line 133, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part .footer_icon {
  text-align: right;
}

/* line 136, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part .footer_icon li {
  display: inline-block;
}

/* line 139, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part .footer_icon li a {
  font-size: 14px;
  color: #7f7f7f;
  margin-left: 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

@media (max-width: 576px) {
  /* line 139, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
  .footer_part .footer_icon li a {
    margin-left: 0px;
    margin-right: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 139, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
  .footer_part .footer_icon li a {
    margin-left: 0px;
    margin-right: 25px;
  }
}

/* line 160, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part .footer_icon li a :hover {
  color: #0ab6ff;
}

@media (max-width: 576px) {
  /* line 133, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
  .footer_part .footer_icon {
    float: left;
    margin-top: 15px;
  }
}

/* line 179, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part .copyright_text p {
  color: #888;
}

/* line 183, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part .copyright_text span {
  color: #0ab6ff;
  font-size: 12px;
}

@media (max-width: 991px) {
  /* line 178, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
  .footer_part .copyright_text {
    text-align: center !important;
  }
}

/* line 195, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part .copyright_text p a {
  color: #0ab6ff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 199, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part .copyright_text p a:hover {
  color: #0ab6ff;
}

/* line 204, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part .info {
  color: red !important;
}

@media (max-width: 991px) {
  /* line 209, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
  .footer_part .footer_icon {
    text-align: center;
    float: none;
  }
}

.footer_part .single_footer_part .social_icon {
  float: left;
  margin-top: 38px;
  margin-right: 30px;
}

@media (max-width: 991px) {
  /* line 77, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
  .footer_part .single_footer_part .social_icon {
    margin-top: 15px;
    margin-right: 30px;
  }
}

/* line 88, ../../01 cl html template/03_jun 2019/184_SAAS_template/sass/_footer.scss */
.footer_part .single_footer_part .social_icon li a {
  margin-left: 20px;
  margin-right: 25px;
}

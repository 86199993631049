


.sidebar .nav > .nav-item.active > a:before, .sidebar[data-background-color="white"] .nav > .nav-item.active > a:before, .sidebar .nav > .nav-item.active:hover > a:before, .sidebar[data-background-color="white"] .nav > .nav-item.active:hover > a:before, .sidebar .nav > .nav-item a[data-toggle=collapse][aria-expanded=true]:before, .sidebar[data-background-color="white"] .nav > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
    opacity: 1 !important;
    z-index: 1;
    width: 3px;
    height: 100%;
    content: '';
    left: 0;
    top: 0; }
  
  .sidebar, .sidebar[data-background-color="white"] {

    top: 0;
    bottom: 0;
    left: 0;
    width: 250px;
    
    display: block;
    z-index: 1000;
    color: #ffffff;
    font-weight: 200;
    background: #ffffff;
    -webkit-box-shadow: 4px 4px 10px rgba(69, 65, 78, 0.06);
    -moz-box-shadow: 4px 4px 10px rgba(69, 65, 78, 0.06);
    box-shadow: 4px 4px 10px rgba(69, 65, 78, 0.06);
    transition: all .3s; }
    .sidebar.full-height, .sidebar[data-background-color="white"].full-height {
      margin-top: 0; }
    .sidebar .user, .sidebar[data-background-color="white"] .user {
      margin-top: 20px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 12.5px;
      border-bottom: 1px solid #f1f1f1;
      display: block;
      margin-left: 10px;
      margin-right: 10px; }
      .sidebar .user .info a, .sidebar[data-background-color="white"] .user .info a {
        white-space: nowrap;
        display: block;
        position: relative; }
        .sidebar .user .info a:hover, .sidebar .user .info a:focus, .sidebar[data-background-color="white"] .user .info a:hover, .sidebar[data-background-color="white"] .user .info a:focus {
          text-decoration: none; }
        .sidebar .user .info a > span, .sidebar[data-background-color="white"] .user .info a > span {
          font-size: 14px;
          font-weight: 400;
          color: #777;
          display: flex;
          flex-direction: column; }
          .sidebar .user .info a > span .user-level, .sidebar[data-background-color="white"] .user .info a > span .user-level {
            color: #555;
            font-weight: 600;
            font-size: 12px;
            margin-top: 5px; }
        .sidebar .user .info a .link-collapse, .sidebar[data-background-color="white"] .user .info a .link-collapse {
          padding: 7px 0; }
      .sidebar .user .info .caret, .sidebar[data-background-color="white"] .user .info .caret {
        position: absolute;
        top: 17px;
        right: 0px;
        border-top-color: #777; }
    .sidebar .sidebar-wrapper, .sidebar[data-background-color="white"] .sidebar-wrapper {
      position: relative;
      max-height: calc(100vh - 75px);
      min-height: 100%;
      overflow: auto;
      width: 100%;
      z-index: 4;
      padding-bottom: 100px;
      transition: all .3s; }
      .sidebar .sidebar-wrapper .sidebar-content, .sidebar[data-background-color="white"] .sidebar-wrapper .sidebar-content {
        padding-top: 0px;
        padding-bottom: 55px; }
      .sidebar .sidebar-wrapper .scroll-element.scroll-y, .sidebar[data-background-color="white"] .sidebar-wrapper .scroll-element.scroll-y {
        top: 5px !important; }
    .sidebar .nav, .sidebar[data-background-color="white"] .nav {
      display: block;
      float: none;
      margin-top: 20px; }
      .sidebar .nav .nav-section, .sidebar[data-background-color="white"] .nav .nav-section {
        margin: 15px 0 0 0; }
        .sidebar .nav .nav-section .sidebar-mini-icon, .sidebar[data-background-color="white"] .nav .nav-section .sidebar-mini-icon {
          text-align: center;
          font-size: 15px;
          color: #909093;
          display: none; }
        .sidebar .nav .nav-section .text-section, .sidebar[data-background-color="white"] .nav .nav-section .text-section {
          padding: 2px 30px;
          font-size: 12px;
          color: #727275;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          margin-bottom: 12px;
          margin-top: 20px; }
      .sidebar .nav > .nav-item, .sidebar[data-background-color="white"] .nav > .nav-item {
        display: list-item; }
        .sidebar .nav > .nav-item.active > a, .sidebar[data-background-color="white"] .nav > .nav-item.active > a {
          color: #575962 !important; }
          .sidebar .nav > .nav-item.active > a:before, .sidebar[data-background-color="white"] .nav > .nav-item.active > a:before {
            background: #1d7af3; }
          .sidebar .nav > .nav-item.active > a p, .sidebar[data-background-color="white"] .nav > .nav-item.active > a p {
            color: #575962 !important;
            font-weight: 600; }
        .sidebar .nav > .nav-item.active:hover > a:before, .sidebar[data-background-color="white"] .nav > .nav-item.active:hover > a:before {
          background: #1d7af3; }
        .sidebar .nav > .nav-item.active a i, .sidebar[data-background-color="white"] .nav > .nav-item.active a i {
          color: #4d7cfe; }
        .sidebar .nav > .nav-item.submenu, .sidebar[data-background-color="white"] .nav > .nav-item.submenu {
          background: rgba(0, 0, 0, 0.03); }
          .sidebar .nav > .nav-item.submenu > li > a i, .sidebar[data-background-color="white"] .nav > .nav-item.submenu > li > a i {
            color: rgba(23, 125, 255, 0.76); }
        .sidebar .nav > .nav-item > a:hover, .sidebar .nav > .nav-item a:focus, .sidebar[data-background-color="white"] .nav > .nav-item > a:hover, .sidebar[data-background-color="white"] .nav > .nav-item a:focus {
          background: rgba(0, 0, 0, 0.03); }
        .sidebar .nav > .nav-item a, .sidebar[data-background-color="white"] .nav > .nav-item a {
          display: flex;
          align-items: center;
          color: #575962;
          padding: 6px 25px;
          width: 100%;
          font-size: 14px;
          font-weight: 400;
          position: relative;
          margin-bottom: 3px; }
          .sidebar .nav > .nav-item a:hover, .sidebar .nav > .nav-item a:focus, .sidebar[data-background-color="white"] .nav > .nav-item a:hover, .sidebar[data-background-color="white"] .nav > .nav-item a:focus {
            text-decoration: none; }
            .sidebar .nav > .nav-item a:hover p, .sidebar .nav > .nav-item a:focus p, .sidebar[data-background-color="white"] .nav > .nav-item a:hover p, .sidebar[data-background-color="white"] .nav > .nav-item a:focus p {
              color: #575962 !important;
              font-weight: 600; }
            .sidebar .nav > .nav-item a:hover i, .sidebar .nav > .nav-item a:focus i, .sidebar[data-background-color="white"] .nav > .nav-item a:hover i, .sidebar[data-background-color="white"] .nav > .nav-item a:focus i {
              color: #4d7cfe !important; }
        .sidebar .nav > .nav-item a .letter-icon, .sidebar[data-background-color="white"] .nav > .nav-item a .letter-icon {
          color: #a1a2a6;
          margin-right: 15px;
          width: 25px;
          text-align: center;
          vertical-align: middle;
          float: left;
          font-size: 20px;
          font-weight: 200; }
        .sidebar .nav > .nav-item a i, .sidebar[data-background-color="white"] .nav > .nav-item a i {
          color: #8d9498;
          margin-right: 15px;
          width: 25px;
          text-align: center;
          vertical-align: middle;
          float: left;
          font-size: 18px;
          line-height: 30px; }
          .sidebar .nav > .nav-item a i[class^="flaticon-"], .sidebar[data-background-color="white"] .nav > .nav-item a i[class^="flaticon-"] {
            font-size: 20px; }
        .sidebar .nav > .nav-item a p, .sidebar[data-background-color="white"] .nav > .nav-item a p {
          font-size: 14px;
          margin-bottom: 0px;
          margin-right: 5px;
          white-space: nowrap;
          color: #8d9498; }
        .sidebar .nav > .nav-item a .caret, .sidebar[data-background-color="white"] .nav > .nav-item a .caret {
          margin-left: auto;
          margin-right: 10px;
          transition: all .5s;
          color: #8d9498; }
        .sidebar .nav > .nav-item a[data-toggle=collapse][aria-expanded=true], .sidebar[data-background-color="white"] .nav > .nav-item a[data-toggle=collapse][aria-expanded=true] {
          background: transparent; }
          .sidebar .nav > .nav-item a[data-toggle=collapse][aria-expanded=true] p, .sidebar[data-background-color="white"] .nav > .nav-item a[data-toggle=collapse][aria-expanded=true] p {
            color: #575962; }
          .sidebar .nav > .nav-item a[data-toggle=collapse][aria-expanded=true] i, .sidebar[data-background-color="white"] .nav > .nav-item a[data-toggle=collapse][aria-expanded=true] i {
            color: #4d7cfe; }
          .sidebar .nav > .nav-item a[data-toggle=collapse][aria-expanded=true] .caret, .sidebar[data-background-color="white"] .nav > .nav-item a[data-toggle=collapse][aria-expanded=true] .caret {
            filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
            -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg); }
          .sidebar .nav > .nav-item a[data-toggle=collapse][aria-expanded=true]:before, .sidebar[data-background-color="white"] .nav > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
            background: #1d7af3; }
      .sidebar .nav.nav-primary > .nav-item a:hover i, .sidebar .nav.nav-primary > .nav-item a:focus i, .sidebar .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true] i, .sidebar[data-background-color="white"] .nav.nav-primary > .nav-item a:hover i, .sidebar[data-background-color="white"] .nav.nav-primary > .nav-item a:focus i, .sidebar[data-background-color="white"] .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true] i {
        color: #1572E8 !important; }
      .sidebar .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true]:before, .sidebar[data-background-color="white"] .nav.nav-primary > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
        background: #1572E8 !important; }
      .sidebar .nav.nav-primary > .nav-item.active a:before, .sidebar[data-background-color="white"] .nav.nav-primary > .nav-item.active a:before {
        background: #1572E8 !important; }
      .sidebar .nav.nav-primary > .nav-item.active a i, .sidebar[data-background-color="white"] .nav.nav-primary > .nav-item.active a i {
        color: #1572E8 !important; }
      .sidebar .nav.nav-secondary > .nav-item a:hover i, .sidebar .nav.nav-secondary > .nav-item a:focus i, .sidebar .nav.nav-secondary > .nav-item a[data-toggle=collapse][aria-expanded=true] i, .sidebar[data-background-color="white"] .nav.nav-secondary > .nav-item a:hover i, .sidebar[data-background-color="white"] .nav.nav-secondary > .nav-item a:focus i, .sidebar[data-background-color="white"] .nav.nav-secondary > .nav-item a[data-toggle=collapse][aria-expanded=true] i {
        color: #6861CE !important; }
      .sidebar .nav.nav-secondary > .nav-item a[data-toggle=collapse][aria-expanded=true]:before, .sidebar[data-background-color="white"] .nav.nav-secondary > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
        background: #6861CE !important; }
      .sidebar .nav.nav-secondary > .nav-item.active a:before, .sidebar[data-background-color="white"] .nav.nav-secondary > .nav-item.active a:before {
        background: #6861CE !important; }
      .sidebar .nav.nav-secondary > .nav-item.active a i, .sidebar[data-background-color="white"] .nav.nav-secondary > .nav-item.active a i {
        color: #6861CE !important; }
      .sidebar .nav.nav-info > .nav-item a:hover i, .sidebar .nav.nav-info > .nav-item a:focus i, .sidebar .nav.nav-info > .nav-item a[data-toggle=collapse][aria-expanded=true] i, .sidebar[data-background-color="white"] .nav.nav-info > .nav-item a:hover i, .sidebar[data-background-color="white"] .nav.nav-info > .nav-item a:focus i, .sidebar[data-background-color="white"] .nav.nav-info > .nav-item a[data-toggle=collapse][aria-expanded=true] i {
        color: #48ABF7 !important; }
      .sidebar .nav.nav-info > .nav-item a[data-toggle=collapse][aria-expanded=true]:before, .sidebar[data-background-color="white"] .nav.nav-info > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
        background: #48ABF7 !important; }
      .sidebar .nav.nav-info > .nav-item.active a:before, .sidebar[data-background-color="white"] .nav.nav-info > .nav-item.active a:before {
        background: #48ABF7 !important; }
      .sidebar .nav.nav-info > .nav-item.active a i, .sidebar[data-background-color="white"] .nav.nav-info > .nav-item.active a i {
        color: #48ABF7 !important; }
      .sidebar .nav.nav-success > .nav-item a:hover i, .sidebar .nav.nav-success > .nav-item a:focus i, .sidebar .nav.nav-success > .nav-item a[data-toggle=collapse][aria-expanded=true] i, .sidebar[data-background-color="white"] .nav.nav-success > .nav-item a:hover i, .sidebar[data-background-color="white"] .nav.nav-success > .nav-item a:focus i, .sidebar[data-background-color="white"] .nav.nav-success > .nav-item a[data-toggle=collapse][aria-expanded=true] i {
        color: #31CE36 !important; }
      .sidebar .nav.nav-success > .nav-item a[data-toggle=collapse][aria-expanded=true]:before, .sidebar[data-background-color="white"] .nav.nav-success > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
        background: #31CE36 !important; }
      .sidebar .nav.nav-success > .nav-item.active a:before, .sidebar[data-background-color="white"] .nav.nav-success > .nav-item.active a:before {
        background: #31CE36 !important; }
      .sidebar .nav.nav-success > .nav-item.active a i, .sidebar[data-background-color="white"] .nav.nav-success > .nav-item.active a i {
        color: #31CE36 !important; }
      .sidebar .nav.nav-warning > .nav-item a:hover i, .sidebar .nav.nav-warning > .nav-item a:focus i, .sidebar .nav.nav-warning > .nav-item a[data-toggle=collapse][aria-expanded=true] i, .sidebar[data-background-color="white"] .nav.nav-warning > .nav-item a:hover i, .sidebar[data-background-color="white"] .nav.nav-warning > .nav-item a:focus i, .sidebar[data-background-color="white"] .nav.nav-warning > .nav-item a[data-toggle=collapse][aria-expanded=true] i {
        color: #FFAD46 !important; }
      .sidebar .nav.nav-warning > .nav-item a[data-toggle=collapse][aria-expanded=true]:before, .sidebar[data-background-color="white"] .nav.nav-warning > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
        background: #FFAD46 !important; }
      .sidebar .nav.nav-warning > .nav-item.active a:before, .sidebar[data-background-color="white"] .nav.nav-warning > .nav-item.active a:before {
        background: #FFAD46 !important; }
      .sidebar .nav.nav-warning > .nav-item.active a i, .sidebar[data-background-color="white"] .nav.nav-warning > .nav-item.active a i {
        color: #FFAD46 !important; }
      .sidebar .nav.nav-danger > .nav-item a:hover i, .sidebar .nav.nav-danger > .nav-item a:focus i, .sidebar .nav.nav-danger > .nav-item a[data-toggle=collapse][aria-expanded=true] i, .sidebar[data-background-color="white"] .nav.nav-danger > .nav-item a:hover i, .sidebar[data-background-color="white"] .nav.nav-danger > .nav-item a:focus i, .sidebar[data-background-color="white"] .nav.nav-danger > .nav-item a[data-toggle=collapse][aria-expanded=true] i {
        color: #F25961 !important; }
      .sidebar .nav.nav-danger > .nav-item a[data-toggle=collapse][aria-expanded=true]:before, .sidebar[data-background-color="white"] .nav.nav-danger > .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
        background: #F25961 !important; }
      .sidebar .nav.nav-danger > .nav-item.active a:before, .sidebar[data-background-color="white"] .nav.nav-danger > .nav-item.active a:before {
        background: #F25961 !important; }
      .sidebar .nav.nav-danger > .nav-item.active a i, .sidebar[data-background-color="white"] .nav.nav-danger > .nav-item.active a i {
        color: #F25961 !important; }
    .sidebar .nav-collapse, .sidebar[data-background-color="white"] .nav-collapse {
      margin-top: 0px;
      margin-bottom: 15px;
      padding-bottom: 15px;
      padding-top: 10px; }
      .sidebar .nav-collapse li.active > a, .sidebar[data-background-color="white"] .nav-collapse li.active > a {
        font-weight: 600; }
      .sidebar .nav-collapse li a:before, .sidebar .nav-collapse li a:hover:before, .sidebar[data-background-color="white"] .nav-collapse li a:before, .sidebar[data-background-color="white"] .nav-collapse li a:hover:before {
        opacity: 0 !important; }
      .sidebar .nav-collapse li a, .sidebar[data-background-color="white"] .nav-collapse li a {
        margin-bottom: 3px !important;
        padding: 10px 25px !important; }
        .sidebar .nav-collapse li a .sub-item, .sidebar[data-background-color="white"] .nav-collapse li a .sub-item {
          font-size: 14px;
          position: relative;
          margin-left: 25px;
          opacity: .85; }
          .sidebar .nav-collapse li a .sub-item:before, .sidebar[data-background-color="white"] .nav-collapse li a .sub-item:before {
            content: '';
            height: 4px;
            width: 4px;
            background: rgba(131, 132, 138, 0.89);
            position: absolute;
            left: -15px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 100%; }
        .sidebar .nav-collapse li a:hover .sub-item, .sidebar[data-background-color="white"] .nav-collapse li a:hover .sub-item {
          opacity: 1; }
        .sidebar .nav-collapse li a .sidebar-mini-icon, .sidebar[data-background-color="white"] .nav-collapse li a .sidebar-mini-icon {
          font-size: 18px;
          color: #C3C5CA;
          margin-right: 15px;
          width: 25px;
          text-align: center;
          vertical-align: middle;
          float: left;
          font-weight: 300 !important; }
      .sidebar .nav-collapse.subnav, .sidebar[data-background-color="white"] .nav-collapse.subnav {
        padding-bottom: 10px;
        margin-bottom: 0px; }
        .sidebar .nav-collapse.subnav li a, .sidebar[data-background-color="white"] .nav-collapse.subnav li a {
          padding-left: 40px !important; }
  
  /* Sidebar style 2 */
  .sidebar.sidebar-style-2 .nav .nav-item {
    padding: 0 15px; }
    .sidebar.sidebar-style-2 .nav .nav-item a {
      padding: 8px 10px;
      border-radius: 5px; }
    .sidebar.sidebar-style-2 .nav .nav-item a:hover, .sidebar.sidebar-style-2 .nav .nav-item a:focus, .sidebar.sidebar-style-2 .nav .nav-item a[data-toggle=collapse][aria-expanded=true] {
      background: rgba(199, 199, 199, 0.2); }
      .sidebar.sidebar-style-2 .nav .nav-item a:hover p, .sidebar.sidebar-style-2 .nav .nav-item a:hover i, .sidebar.sidebar-style-2 .nav .nav-item a:focus p, .sidebar.sidebar-style-2 .nav .nav-item a:focus i, .sidebar.sidebar-style-2 .nav .nav-item a[data-toggle=collapse][aria-expanded=true] p, .sidebar.sidebar-style-2 .nav .nav-item a[data-toggle=collapse][aria-expanded=true] i {
        color: #575962 !important; }
    .sidebar.sidebar-style-2 .nav .nav-item.active a:before {
      background: transparent; }
    .sidebar.sidebar-style-2 .nav .nav-item .active a {
      background: rgba(199, 199, 199, 0.2); }
      .sidebar.sidebar-style-2 .nav .nav-item .active a p, .sidebar.sidebar-style-2 .nav .nav-item .active a i {
        color: #575962 !important; }
    .sidebar.sidebar-style-2 .nav .nav-item.submenu {
      background: transparent !important; }
    .sidebar.sidebar-style-2 .nav .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
      background: transparent !important; }
  .sidebar.sidebar-style-2 .nav.nav-primary > .nav-item.active > a {
    background: #1572E8 !important;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1), 4px 4px 15px -5px rgba(21, 114, 232, 0.4) !important; }
    .sidebar.sidebar-style-2 .nav.nav-primary > .nav-item.active > a:before {
      background: transparent !important; }
    .sidebar.sidebar-style-2 .nav.nav-primary > .nav-item.active > a p, .sidebar.sidebar-style-2 .nav.nav-primary > .nav-item.active > a i, .sidebar.sidebar-style-2 .nav.nav-primary > .nav-item.active > a .caret, .sidebar.sidebar-style-2 .nav.nav-primary > .nav-item.active > a span {
      color: #ffffff !important; }
    .sidebar.sidebar-style-2 .nav.nav-primary > .nav-item.active > a[data-toggle=collapse][aria-expanded=true] i {
      color: #ffffff !important; }
  .sidebar.sidebar-style-2 .nav.nav-secondary > .nav-item.active > a {
    background: #6861CE !important;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1), 4px 4px 15px -5px rgba(104, 97, 206, 0.4) !important; }
    .sidebar.sidebar-style-2 .nav.nav-secondary > .nav-item.active > a:before {
      background: transparent !important; }
    .sidebar.sidebar-style-2 .nav.nav-secondary > .nav-item.active > a p, .sidebar.sidebar-style-2 .nav.nav-secondary > .nav-item.active > a i, .sidebar.sidebar-style-2 .nav.nav-secondary > .nav-item.active > a .caret, .sidebar.sidebar-style-2 .nav.nav-secondary > .nav-item.active > a span {
      color: #ffffff !important; }
    .sidebar.sidebar-style-2 .nav.nav-secondary > .nav-item.active > a[data-toggle=collapse][aria-expanded=true] i {
      color: #ffffff !important; }
  .sidebar.sidebar-style-2 .nav.nav-info > .nav-item.active > a {
    background: #48ABF7 !important;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1), 4px 4px 15px -5px rgba(72, 171, 247, 0.4) !important; }
    .sidebar.sidebar-style-2 .nav.nav-info > .nav-item.active > a:before {
      background: transparent !important; }
    .sidebar.sidebar-style-2 .nav.nav-info > .nav-item.active > a p, .sidebar.sidebar-style-2 .nav.nav-info > .nav-item.active > a i, .sidebar.sidebar-style-2 .nav.nav-info > .nav-item.active > a .caret, .sidebar.sidebar-style-2 .nav.nav-info > .nav-item.active > a span {
      color: #ffffff !important; }
    .sidebar.sidebar-style-2 .nav.nav-info > .nav-item.active > a[data-toggle=collapse][aria-expanded=true] i {
      color: #ffffff !important; }
  .sidebar.sidebar-style-2 .nav.nav-success > .nav-item.active > a {
    background: #31CE36 !important;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1), 4px 4px 15px -5px rgba(49, 206, 54, 0.4) !important; }
    .sidebar.sidebar-style-2 .nav.nav-success > .nav-item.active > a:before {
      background: transparent !important; }
    .sidebar.sidebar-style-2 .nav.nav-success > .nav-item.active > a p, .sidebar.sidebar-style-2 .nav.nav-success > .nav-item.active > a i, .sidebar.sidebar-style-2 .nav.nav-success > .nav-item.active > a .caret, .sidebar.sidebar-style-2 .nav.nav-success > .nav-item.active > a span {
      color: #ffffff !important; }
    .sidebar.sidebar-style-2 .nav.nav-success > .nav-item.active > a[data-toggle=collapse][aria-expanded=true] i {
      color: #ffffff !important; }
  .sidebar.sidebar-style-2 .nav.nav-warning > .nav-item.active > a {
    background: #FFAD46 !important;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1), 4px 4px 15px -5px rgba(255, 173, 70, 0.4) !important; }
    .sidebar.sidebar-style-2 .nav.nav-warning > .nav-item.active > a:before {
      background: transparent !important; }
    .sidebar.sidebar-style-2 .nav.nav-warning > .nav-item.active > a p, .sidebar.sidebar-style-2 .nav.nav-warning > .nav-item.active > a i, .sidebar.sidebar-style-2 .nav.nav-warning > .nav-item.active > a .caret, .sidebar.sidebar-style-2 .nav.nav-warning > .nav-item.active > a span {
      color: #ffffff !important; }
    .sidebar.sidebar-style-2 .nav.nav-warning > .nav-item.active > a[data-toggle=collapse][aria-expanded=true] i {
      color: #ffffff !important; }
  .sidebar.sidebar-style-2 .nav.nav-danger > .nav-item.active > a {
    background: #F25961 !important;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.1), 4px 4px 15px -5px rgba(242, 89, 97, 0.4) !important; }
    .sidebar.sidebar-style-2 .nav.nav-danger > .nav-item.active > a:before {
      background: transparent !important; }
    .sidebar.sidebar-style-2 .nav.nav-danger > .nav-item.active > a p, .sidebar.sidebar-style-2 .nav.nav-danger > .nav-item.active > a i, .sidebar.sidebar-style-2 .nav.nav-danger > .nav-item.active > a .caret, .sidebar.sidebar-style-2 .nav.nav-danger > .nav-item.active > a span {
      color: #ffffff !important; }
    .sidebar.sidebar-style-2 .nav.nav-danger > .nav-item.active > a[data-toggle=collapse][aria-expanded=true] i {
      color: #ffffff !important; }
  .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item a:hover p, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item a:hover i, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item a:focus p, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item a:focus i, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item a[data-toggle=collapse][aria-expanded=true] p, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item a[data-toggle=collapse][aria-expanded=true] i, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item a:hover p, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item a:hover i, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item a:focus p, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item a:focus i, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item a[data-toggle=collapse][aria-expanded=true] p, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item a[data-toggle=collapse][aria-expanded=true] i {
    color: #b9babf !important; }
  .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item.active a, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item.active a {
    color: #ffffff; }
    .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item.active a p, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item.active a i, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item.active a .caret, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item.active a span, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item.active a p, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item.active a i, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item.active a .caret, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item.active a span {
      color: #ffffff; }
    .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] p, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] i, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] .caret, .sidebar.sidebar-style-2[data-background-color="dark"] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] span, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] p, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] i, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] .caret, .sidebar.sidebar-style-2[data-background-color="dark2"] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] span {
      color: #ffffff; }

    
      .avatar-sm {
        width: 2.5rem;
        height: 2.5rem; }
        .avatar-sm .border {
          border-width: 3px !important; }
        .avatar-sm .rounded {
          border-radius: 4px !important; }
        .avatar-sm .avatar-title {
          font-size: 15px; }
        .avatar-sm.avatar-online::before, .avatar-sm.avatar-offline::before, .avatar-sm.avatar-away::before {
          border-width: 2px; }